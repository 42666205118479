import type { IAllCasesItem } from '../Types/allCases/client.types';
import { transform } from '~/features/mainPage/Types/allCases/adapter';

export const useAllCasesStore = defineStore('mainPage/allCases', () => {
  const {
    $api: { mainPage: MainPageService },
    $i18n: { t },
  } = useNuxtApp();

  const isLoaded = ref(false);

  /* Const */
  /* Данные о секциях */
  const sections = ref<IAllCasesItem[]>([]);

  /* Methods */
  /* Методы получения данных о секциях */
  const fetch = async () => {
    if (sections.value.length) return; // Не загружать данные, если уже есть

    try {
      const data = await MainPageService.getAllCases();
      sections.value = transform(data, t);
    } catch {
    } finally {
      isLoaded.value = true;
    }
  };

  return {
    fetch,
    isLoaded,
    sections,
  };
});
