import type { ICasesItem } from '../Types/cases/client.types';
import { useAllCasesStore } from './allCases.store';

export const useEventCasesStore = defineStore('mainPage/eventCases', () => {
  /* Стор всех кейсов, для получения коин кейсов */
  const allCasesStore = useAllCasesStore();

  /* Getters */
  /* Настройки названия секции */
  const section = computed(() => {
    return allCasesStore.sections.find((section) => section.isEvent);
  });

  const titleOptions = computed(() => {
    return section.value?.titleOptions ?? undefined;
  });

  /* Настройки цветов кейсов секции */
  const colorsOptions = computed(() => section.value?.colorsOptions ?? undefined);

  /* Отформатированный вид кейсов */
  const cases = computed<ICasesItem[]>(() => section.value?.cases ?? []);

  return {
    cases,
    colorsOptions,
    section,
    titleOptions,
  };
});
