import type { ComposerTranslation } from '#i18n';
import {
  getCoinLevel,
  getCoinsData,
  getColorData,
  getCounterData,
  getImage,
  getLabelData,
  getPriceData,
} from '../cases/adapter';
import type { ICasesApiItem } from '../cases/api.types';
import type { ICasesItem } from '../cases/client.types';
import type { IAllCasesAPIItem, IAllCasesAPIItemTitle } from './api.types';
import type { IAllCasesItem } from './client.types';
import type { ISectionTitleProps } from '~/components/SectionTitle/SectionTitle.types';

export const transform = (apiData: IAllCasesAPIItem[], t: ComposerTranslation): IAllCasesItem[] => {
  const result: IAllCasesItem[] = [];

  for (const section of apiData) {
    const lineBreaks = section.lineBreaks && !isNaN(+section.lineBreaks) ? +section.lineBreaks : undefined;

    result.push({
      cases: getSectionCases(section.cases, t),
      colorsOptions: getColorData(section.title.colorData) ?? undefined,
      id: section.id,
      isEvent: section.isEvent,
      lineBreaks,
      titleOptions: getSectionTitle(section.title, section.name),
    });
  }

  return result;
};

const getSectionCases = (cases: ICasesApiItem[], t: ComposerTranslation): ICasesItem[] => {
  const result: ICasesItem[] = [];

  for (let i = 0; i < cases.length; i++) {
    const currentCase = cases[i];
    const priceData = getPriceData(currentCase.priceData);
    const level = priceData.coins ? getCoinLevel(i) : undefined;

    result.push({
      coinsData: currentCase.tastyCoins ? getCoinsData(currentCase.tastyCoins) : null,
      counterData: getCounterData(currentCase.statisticData),
      disabled: !currentCase.available,
      id: currentCase.id,
      image: getImage(currentCase.imageData.image),
      labelData: getLabelData(currentCase, t),
      level,
      link: currentCase.name,
      name: currentCase.label,
      priceData,
    });
  }

  return result;
};

const getSectionTitle = (title: IAllCasesAPIItemTitle, name: string): ISectionTitleProps => {
  return {
    image: title.icon,
    lineColor:
      title.gradient && title.gradient.length > 0
        ? {
            colorStops: title.gradient,
            toDirection: '270deg',
          }
        : '',
    text: name,
    textColor:
      title.gradientText && title.gradientText.length > 0
        ? {
            colorStops: title.gradientText,
            toDirection: '180deg',
          }
        : '',
  };
};
